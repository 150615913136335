.game-play{
    margin-top: 10vh;
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.upperdeck {
    /* border: solid red 1px; */
    width: 100%;
    min-height: 40vh;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

#ticket-area {
    width: 40%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ticket-display {
    width: 100%;
    min-height: 30vh;
    border: solid rgb(78, 70, 70) 5px;
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    grid-template-rows: auto auto auto;
    justify-content: space-around;
    align-content: space-around;
}

.ticket-0{
    grid-column: 1/2;
    grid-row: 1/2;
}

.ticket-1{
    grid-column: 2/3;
    grid-row: 1/2;
}
.ticket-2{
    grid-column: 3/4;
    grid-row: 1/2;
}

.ticket-3{
    grid-column: 4/5;
    grid-row: 1/2;
}

.ticket-4{
    grid-column: 5/6;
    grid-row: 1/2;
}
.ticket-5{
    grid-column: 1/2;
    grid-row: 2/3;
}
.ticket-6{
    grid-column: 2/3;
    grid-row: 2/3;
}
.ticket-7{
    grid-column: 3/4;
    grid-row: 2/3;
}
.ticket-8{
    grid-column: 4/5;
    grid-row: 2/3;
}
.ticket-9{
    grid-column: 5/6;
    grid-row: 2/3;
}
.ticket-10{
    grid-column: 1/2;
    grid-row: 3/4;
}
.ticket-11 {
    grid-column: 2/3;
    grid-row: 3/4;
}
.ticket-12{
    grid-column: 3/4;
    grid-row: 3/4;
}
.ticket-13{
    grid-column: 4/5;
    grid-row: 3/4;
}   
.ticket-14{
    grid-column: 5/6;
    grid-row: 3/4;
}

#number-area {
    width: 30%;
    height: auto;
}

.game-start-timer {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.top-number {
    /* border: solid blue 1px; */
    width: 100%;
    min-height: 30vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 900%;
}

.lowerdeck {
    /* border: solid blue 1px; */
    width: 100%;
    min-height: 40vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.all-numbers {
    border: solid rgb(78, 70, 70) 5px;
    width: 90%;
    min-height: 90%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    align-content: flex-start;
}

.number-box {
    padding: 5px 10px 5px 10px;
    border: solid rgb(78, 70, 70) 1px;
    margin: 1px;
}

/* .number-hover:hover {
    cursor: pointer;
    background-color: #111;
} */

.yellow-number {
    background-color: rgb(204, 204, 45);
}

/* .yellow-number:hover {
    cursor: pointer;
    background-color: rgb(253, 253, 0);
} */

.hidden {
    display: none;
}