.waiting {
    margin-top: 10vh;
    min-height: 80vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.wait-info {
    /* border: solid rgb(78, 70, 70) 5px; */
    width: 60vw;
    height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    font-size: x-large;
}