* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.App {
  color: white;
  /* background-color: #212121;s */
}
