.nav-bar {
    position: fixed;
    top: 0;
    width: 100%;
    text-align: left;
    height: 8vh;
    background-color: rgb(78, 70, 70);
    /* display: flex;
    justify-content: center;
    align-items: center; */
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

#links {
    grid-column: 2/3;
    display: flex;
    align-items: center;
}

.nav-links {
    height: 100%;
    color: whitesmoke;
    font-size: 200%;
    text-decoration: none;
}

#heading{
    margin-left: auto;
    margin-right: auto;
}

.sub-links {
    display: flex;
    align-items: center;
    font-size: 150%;
}

#dev {
    grid-column: 3/4;
    font-size: 150%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    justify-items: end;
    margin-right: 0.5rem;
}

#port {
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

#ashwin {
    text-decoration: none;
}