.home {
    margin-top: 10vh;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 80vh;
}

.game-info {
    border: solid rgb(78, 70, 70) 5px;
    width: 60vw;
    height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: center;
}

.game-heading {
    /* border: solid red 1px; */
    width: 100%;
    height: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 200%;
}

.price-timer {
    /* border: solid blue 1px; */
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.ticket-price {
    /* border: solid green 1px; */
    width: 40%;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.game-timer {
    /* border: solid red 1px; */
    width: 25%;
    height: 60%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.countdown {
    text-align: center;
}

.game-button {
    /* border: solid green 1px; */
    width: 100%;
    height: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 200%;
}

#ticket-button {
    height: 40%;
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 50%;
    color: white;
    background-color:  rgb(78, 70, 70);
    border: 0;
    text-decoration: none;
}

#ticket-button:hover {
    border: 0;
    cursor: pointer;
    background-color: #353535;
}

#metamaskDiv {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

#metamaskButton {
    width: 80%;
    height: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color:  rgb(78, 70, 70);
    font-size: 150%;
    color: white;
    border: 0;
    margin-bottom: 4rem;
}

#metamaskButton:hover {
    border: 0;
    cursor: pointer;
    background-color: #353535;   
}

#linkIcon {
    margin-left: 0.5rem;
    color: white;
    width: 6%;
    height: auto;
}